
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layouts/admin/admin.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { AuthGuard } from './guard/auth.guard';
import { LeaveGuard } from './leaved/leaved.guard';
import { procurementportalComponent } from './layouts/procurementportal/procurementportal.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'activitytracker',
        loadChildren: () => import('./pages/activitytracker/activitytracker.module').then(m => m.activitytrackerModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'manage-activitytracker',
        loadChildren: () => import('./pages/manage-activitytracker/manage-activitytracker.module').then(m => m.ManageactivitytrackerModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'accrual',
        loadChildren: () => import('./pages/accrual/accrual.module').then(m => m.accrualModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'audit',
        loadChildren: () => import('./pages/audit/audit.module').then(m => m.AuditModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-audit',
        loadChildren: () => import('./pages/manage-audit/manage-audit.module').then(m => m.ManageAuditModule),
		canActivate: [AuthGuard]
		
      },

	  {
        path: 'amc',
        loadChildren: () => import('./pages/amc/amc.module').then(m => m.AmcModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-amc',
        loadChildren: () => import('./pages/manage-amc/manage-amc.module').then(m => m.ManageAmcModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'amctracker',
        loadChildren: () => import('./pages/amctracker/amctracker.module').then(m => m.amctrackerModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'approval',
        loadChildren: () => import('./pages/approval/approval.module').then(m => m.ApprovalModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-approval',
        loadChildren: () => import('./pages/manage-approval/manage-approval.module').then(m => m.ManageApprovalModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'asset',
        loadChildren: () => import('./pages/asset/asset.module').then(m => m.AssetModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-asset',
        loadChildren: () => import('./pages/manage-asset/manage-asset.module').then(m => m.ManageAssetModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'assetcategory',
        loadChildren: () => import('./pages/assetcategory/assetcategory.module').then(m => m.AssetcategoryModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-assetcategory',
        loadChildren: () => import('./pages/manage-assetcategory/manage-assetcategory.module').then(m => m.ManageAssetcategoryModule),
		canActivate: [AuthGuard]
      },
       {
        path: 'budget',
        loadChildren: () => import('./pages/budget/budget.module').then(m => m.budgetModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-budget',
        loadChildren: () => import('./pages/manage-budget/manage-budget.module').then(m => m.ManagebudgetModule),
		canActivate: [AuthGuard]
		
      },
      {
        path: 'budgetreport',
        loadChildren: () => import('./pages/budgetreport/budgetreport.module').then(m => m.budgetreportModule),
		canActivate: [AuthGuard]
      },
	  
	  {
        path: 'consumption',
        loadChildren: () => import('./pages/consumption/consumption.module').then(m => m.ConsumptionModule),
		canActivate: [AuthGuard]
      },
	  
	  {
        path: 'manage-consumption',
        loadChildren: () => import('./pages/manage-consumption/manage-consumption.module').then(m => m.ManageConsumptionModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'consumptionitemrate',
        loadChildren: () => import('./pages/consumptionitemrate/consumptionitemrate.module').then(m => m.ConsumptionitemrateModule),
		canActivate: [AuthGuard]
      },
	  
	  {
        path: 'manage-consumptionitemrate',
        loadChildren: () => import('./pages/manage-consumptionitemrate/manage-consumptionitemrate.module').then(m => m.ManageConsumptionitemrateModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'prconsumption',
        loadChildren: () => import('./pages/prconsumption/prconsumption.module').then(m => m.prconsumptionModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'pritemreceive',
        loadChildren: () => import('./pages/pritemreceive/pritemreceive.module').then(m => m.pritemreceiveModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'prcategory',
        loadChildren: () => import('./pages/prcategory/prcategory.module').then(m => m.prcategoryModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-prcategory',
        loadChildren: () => import('./pages/manage-prcategory/manage-prcategory.module').then(m => m.ManageprcategoryModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'document',
        loadChildren: () => import('./pages/document/document.module').then(m => m.DocumentModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-document',
        loadChildren: () => import('./pages/manage-document/manage-document.module').then(m => m.ManageDocumentModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'driver',
        loadChildren: () => import('./pages/driver/driver.module').then(m => m.DriverModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-driver',
        loadChildren: () => import('./pages/manage-driver/manage-driver.module').then(m => m.ManageDriverModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'ebdg',
        loadChildren: () => import('./pages/ebdg/ebdg.module').then(m => m.EbdgModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-ebdg',
        loadChildren: () => import('./pages/manage-ebdg/manage-ebdg.module').then(m => m.ManageEbdgModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'ebmeter',
        loadChildren: () => import('./pages/ebmeter/ebmeter.module').then(m => m.ebmeterModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-ebmeter',
        loadChildren: () => import('./pages/manage-ebmeter/manage-ebmeter.module').then(m => m.ManageebmeterModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'ecr',
        loadChildren: () => import('./pages/ecr/ecr.module').then(m => m.EcrModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-ecr',
        loadChildren: () => import('./pages/manage-ecr/manage-ecr.module').then(m => m.ManageEcrModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'environmentreport',
        loadChildren: () => import('./pages/environmentreport/environmentreport.module').then(m => m.EnvironmentreportModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-environmentreport',
        loadChildren: () => import('./pages/manage-environmentreport/manage-environmentreport.module').then(m => m.ManageEnvironmentreportModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'manage-environmentreportdetails',
        loadChildren: () => import('./pages/manage-environmentreportdetails/manage-environmentreportdetails.module').then(m => m.ManageenvironmentreportdetailsModule),
		canActivate: [AuthGuard]
      },
	   {
        path: 'fire',
        loadChildren: () => import('./pages/fire/fire.module').then(m => m.FireModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-fire',
        loadChildren: () => import('./pages/manage-fire/manage-fire.module').then(m => m.ManageFireModule),
		canActivate: [AuthGuard]
      },
	//   {
    //     path: 'foodreport',
    //     loadChildren: () => import('./pages/foodreport/foodreport.module').then(m => m.FoodreportModule),
	// 	canActivate: [AuthGuard]
    //   },
	//   {
    //     path: 'manage-foodreport',
    //     loadChildren: () => import('./pages/manage-foodreport/manage-foodreport.module').then(m => m.ManageFoodreportModule),
	// 	canActivate: [AuthGuard]
    //   },
      {
        path: 'foodreport',
        loadChildren: () => import('./pages/foodreportnew/foodreportnew.module').then(m => m.foodreportnewModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-foodreport',
        loadChildren: () => import('./pages/manage-foodreportnew/manage-foodreportnew.module').then(m => m.ManagefoodreportnewModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'gatepass',
        loadChildren: () => import('./pages/gatepass/gatepass.module').then(m => m.GatepassModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-gatepass',
        loadChildren: () => import('./pages/manage-gatepass/manage-gatepass.module').then(m => m.ManageGatepassModule),
		canActivate: [AuthGuard]
      },
      
	  {
        path: 'invoicevalidation',
        loadChildren: () => import('./pages/invoicevalidation/invoicevalidation.module').then(m => m.InvoicevalidationModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-invoicevalidation',
        loadChildren: () => import('./pages/manage-invoicevalidation/manage-invoicevalidation.module').then(m => m.ManageInvoicevalidationModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'issuetype',
        loadChildren: () => import('./pages/issuetype/issuetype.module').then(m => m.issuetypeModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-issuetype',
        loadChildren: () => import('./pages/manage-issuetype/manage-issuetype.module').then(m => m.ManageissuetypeModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manpower',
        loadChildren: () => import('./pages/manpower/manpower.module').then(m => m.ManpowerModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-manpower',
        loadChildren: () => import('./pages/manage-manpower/manage-manpower.module').then(m => m.ManageManpowerModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'maintainenece',
        loadChildren: () => import('./pages/maintainenece/maintainenece.module').then(m => m.maintaineneceModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-maintainenece',
        loadChildren: () => import('./pages/manage-maintainenece/manage-maintainenece.module').then(m => m.ManagemaintaineneceModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'mom',
        loadChildren: () => import('./pages/mom/mom.module').then(m => m.MomModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-mom',
        loadChildren: () => import('./pages/manage-mom/manage-mom.module').then(m => m.ManageMomModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'noc',
        loadChildren: () => import('./pages/noc/noc.module').then(m => m.NocModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-noc',
        loadChildren: () => import('./pages/manage-noc/manage-noc.module').then(m => m.ManageNocModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'manage-nocreturn',
        loadChildren: () => import('./pages/manage-nocreturn/manage-nocreturn.module').then(m => m.ManagenocreturnModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'purchaserequistion',
        loadChildren: () => import('./pages/purchaserequistion/purchaserequistion.module').then(m => m.purchaserequistionModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-purchaserequistion',
        loadChildren: () => import('./pages/manage-purchaserequistion/manage-purchaserequistion.module').then(m => m.ManagepurchaserequistionModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'potracker',
        loadChildren: () => import('./pages/potracker/potracker.module').then(m => m.PotrackerModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-potracker',
        loadChildren: () => import('./pages/manage-potracker/manage-potracker.module').then(m => m.ManagePotrackerModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'ppm',
        loadChildren: () => import('./pages/ppm/ppm.module').then(m => m.PpmModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-ppm',
        loadChildren: () => import('./pages/manage-ppm/manage-ppm.module').then(m => m.ManagePpmModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'ppmtracker',
        loadChildren: () => import('./pages/ppmtracker/ppmtracker.module').then(m => m.ppmtrackerModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'procurement',
        loadChildren: () => import('./pages/procurement/procurement.module').then(m => m.ProcurementModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-procurement',
        loadChildren: () => import('./pages/manage-procurement/manage-procurement.module').then(m => m.ManageProcurementModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'project',
        loadChildren: () => import('./pages/project/project.module').then(m => m.ProjectModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-project',
        loadChildren: () => import('./pages/manage-project/manage-project.module').then(m => m.ManageProjectModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'projecttasks',
        loadChildren: () => import('./pages/projecttasks/projecttasks.module').then(m => m.ProjecttasksModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-projecttasks',
        loadChildren: () => import('./pages/manage-projecttasks/manage-projecttasks.module').then(m => m.ManageProjecttasksModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'route',
        loadChildren: () => import('./pages/route/route.module').then(m => m.routeModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-route',
        loadChildren: () => import('./pages/manage-route/manage-route.module').then(m => m.ManagerouteModule),
		canActivate: [AuthGuard]	
      },
	  {
        path: 'rack',
        loadChildren: () => import('./pages/rack/rack.module').then(m => m.RackModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-rack',
        loadChildren: () => import('./pages/manage-rack/manage-rack.module').then(m => m.ManageRackModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'scrap',
        loadChildren: () => import('./pages/scrap/scrap.module').then(m => m.ScrapModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-scrap',
        loadChildren: () => import('./pages/manage-scrap/manage-scrap.module').then(m => m.ManageScrapModule),
		canActivate: [AuthGuard]
      },	  
	  {
        path: 'site',
        loadChildren: () => import('./pages/site/site.module').then(m => m.SiteModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-site',
        loadChildren: () => import('./pages/manage-site/manage-site.module').then(m => m.ManageSiteModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'stpall',
        loadChildren: () => import('./pages/stpall/stpall.module').then(m => m.stpallModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'stp',
        loadChildren: () => import('./pages/stp/stp.module').then(m => m.StpModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-stp',
        loadChildren: () => import('./pages/manage-stp/manage-stp.module').then(m => m.ManageStpModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'user',
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-user',
        loadChildren: () => import('./pages/manage-user/manage-user.module').then(m => m.ManageUserModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-userpassword',
        loadChildren: () => import('./pages/manage-userpassword/manage-userpassword.module').then(m => m.ManageUserpasswordModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'vendor',
        loadChildren: () => import('./pages/vendor/vendor.module').then(m => m.VendorModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-vendor',
        loadChildren: () => import('./pages/manage-vendor/manage-vendor.module').then(m => m.ManageVendorModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'vehicle',
        loadChildren: () => import('./pages/vehicle/vehicle.module').then(m => m.VehicleModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-vehicle',
        loadChildren: () => import('./pages/manage-vehicle/manage-vehicle.module').then(m => m.ManageVehicleModule),
		canActivate: [AuthGuard]
      },	
      {
        path: 'vehicletrack',
        loadChildren: () => import('./pages/vehicletrack/vehicletrack.module').then(m => m.VehicletrackModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-vehicletrack',
        loadChildren: () => import('./pages/manage-vehicletrack/manage-vehicletrack.module').then(m => m.ManageVehicletrackModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'water',
        loadChildren: () => import('./pages/water/water.module').then(m => m.WaterModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-water',
        loadChildren: () => import('./pages/manage-water/manage-water.module').then(m => m.ManageWaterModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'workpermit',
        loadChildren: () => import('./pages/workpermit/workpermit.module').then(m => m.WorkpermitModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-workpermit',
        loadChildren: () => import('./pages/manage-workpermit/manage-workpermit.module').then(m => m.ManageWorkpermitModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'workpermit-new',
        loadChildren: () => import('./pages/workpermit-new/workpermit-new.module').then(m => m.WorkpermitnewModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-workpermit-new',
        loadChildren: () => import('./pages/manage-workpermit-new/manage-workpermit-new.module').then(m => m.ManageWorkpermitnewModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'manage-workpermit-print',
        loadChildren: () => import('./pages/manage-workpermit-print/manage-workpermit-print.module').then(m => m.ManageWorkpermitprintModule),
		canActivate: [AuthGuard]
      },	  
	  {
        path: 'worksheet',
        loadChildren: () => import('./pages/worksheet/worksheet.module').then(m => m.WorksheetModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'manage-worksheet',
        loadChildren: () => import('./pages/manage-worksheet/manage-worksheet.module').then(m => m.ManageWorksheetModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'stats',
        loadChildren: () => import('./pages/stats/stats.module').then(m => m.StatsModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'tripreport',
        loadChildren: () => import('./pages/tripreport/tripreport.module').then(m => m.tripreportModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'consumptionreport',
        loadChildren: () => import('./pages/consumptionreport/consumptionreport.module').then(m => m.consumptionreportModule),
		canActivate: [AuthGuard]
      },
	  {
        path: 'routetripreport',
        loadChildren: () => import('./pages/routetripreport/routetripreport.module').then(m => m.routetripreportModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'vehicleroutetag',
        loadChildren: () => import('./pages/vehicleroutetag/vehicleroutetag.module').then(m => m.vehicleroutetagModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'manage-vehicleroutetag',
        loadChildren: () => import('./pages/manage-vehicleroutetag/manage-vehicleroutetag.module').then(m => m.ManagevehicleroutetagModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'miscellaneous',
        loadChildren: () => import('./pages/miscellaneous/miscellaneous.module').then(m => m.miscellaneousModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'technical',
        loadChildren: () => import('./pages/technical/technical.module').then(m => m.technicalModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'commercial',
        loadChildren: () => import('./pages/commercial/commercial.module').then(m => m.commercialModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'dailycheck',
        loadChildren: () => import('./pages/dailycheck/dailycheck.module').then(m => m.dailycheckModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'transport',
        loadChildren: () => import('./pages/transport/transport.module').then(m => m.transportModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'vehicle',
        loadChildren: () => import('./pages/transport/transport.module').then(m => m.transportModule),
		canActivate: [AuthGuard]
      },
      {
        path: 'complaince',
        loadChildren: () => import('./pages/complaince/complaince.module').then(m => m.complainceModule),
		canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
      },
      {
        path: 'loginpin',
        loadChildren: () => import('./pages/loginpin/loginpin.module').then(m => m.LoginpinModule),
      },
      {
        path: 'reset',
        loadChildren: () => import('./pages/reset/reset.module').then(m => m.ResetModule)
      },
      {
        path: 'setsite',
        loadChildren: () => import('./pages/setsite/setsite.module').then(m => m.setsiteModule),
      },
      {
        path: 'procurementhome',
        loadChildren: () => import('./pages/procurementhome/procurementhome.module').then(m => m.procurementhomeModule),
      },
      {
        path: 'procurementinvoicevalidation',
        loadChildren: () => import('./pages/procurementportal/invoicevalidation/invoicevalidation.module').then(m => m.InvoicevalidationModule),
      },
      {
        path: 'manageprocurementinvoicevalidation',
        loadChildren: () => import('./pages/procurementportal/manage-invoicevalidation/manage-invoicevalidation.module').then(m => m.ManageInvoicevalidationModule),
      },
      {
        path: 'procurementpotracker',
        loadChildren: () => import('./pages/procurementportal/potracker/potracker.module').then(m => m.PotrackerModule),
      },
      {
        path: 'manageprocurementpotracker',
        loadChildren: () => import('./pages/procurementportal/manage-potracker/manage-potracker.module').then(m => m.ManagePotrackerModule),
      },
      {
        path: 'procurementprocurement',
        loadChildren: () => import('./pages/procurementportal/procurement/procurement.module').then(m => m.ProcurementModule),
      },
      {
        path: 'manageprocurementprocurement',
        loadChildren: () => import('./pages/procurementportal/manage-procurement/manage-procurement.module').then(m => m.ManageProcurementModule),
      },
      {
        path: 'procurementscrap',
        loadChildren: () => import('./pages/procurementportal/scrap/scrap.module').then(m => m.ScrapModule),
      },
      {
        path: 'manageprocurementscrap',
        loadChildren: () => import('./pages/procurementportal/manage-scrap/manage-scrap.module').then(m => m.ManageScrapModule),
      },
      {
        path: 'procurementpurchaserequistion',
        loadChildren: () => import('./pages/procurementportal/purchaserequistion/purchaserequistion.module').then(m => m.purchaserequistionModule),
      },
      {
        path: 'manageprocurementpurchaserequistion',
        loadChildren: () => import('./pages/procurementportal/manage-purchaserequistion/manage-purchaserequistion.module').then(m => m.ManagepurchaserequistionModule),
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
