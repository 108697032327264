
import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  icon: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

// const MENUITEMS = [
//   {
//     label: 'Master',
//     main: [
//       {
//         state: 'dashboard',
//         name: 'Dashboard',
//         type: 'link',
//         icon: 'ti-home'
//       },
// 	   {
//         state: 'audit',
//         name: 'Scheduler ',
//         type: 'link',
//         icon: 'ti-id-badge'
//        },
// 	   /*--{
//         state: 'amc',
//         name: 'AMC',
//         type: 'link',
//         icon: 'ti-ticket'
//       },--*/
// 	  {
//         state: 'approval',
//         name: 'Request For Approval',
//         type: 'link',
//         icon: 'ti-email'
//       },
// 	  {
//         state: 'asset',
//         name: 'Asset',
//         type: 'link',
//         icon: 'ti-notepad'
//       },
// 	  /*--{
//         state: 'assetcategory',
//         name: 'Asset Category',
//         type: 'link',
//         icon: 'ti-notepad'
//       },--*/
//       {
//         state: 'budget',
//         name: 'Accural Budget',
//         type: 'link',
//         icon: 'ti-import'
//       },
// 	  {
//         state: 'consumption',
//         name: 'Consumption Tracker',
//         type: 'link',
//         icon: 'ti-bar-chart'
//       },
// 	  // {
//     //     state: 'consumptionitemrate',
//     //     name: 'Consumption Item Rate',
//     //     type: 'link',
//     //     icon: 'ti-bar-chart'
//     //   },
// 	  {
//         state: 'document',
//         name: 'Document',
//         type: 'link',
//         icon: 'ti-layout-width-default'
//       },
// 	   {
//         state: 'driver',
//         name: 'Driver',
//         type: 'link',
//         icon: 'ti-user'
//       },
// 	  {
//         state: 'ebdg',
//         name: 'EB And DG Report',
//         type: 'link',
//         icon: 'ti-receipt'
//       },
//       {
//         state: 'ebmeter',
//         name: 'EB Meter',
//         type: 'link',
//         icon: 'ti-envelope'
//       },
// 	  {
//         state: 'ecr',
//         name: 'ECR',
//         type: 'link',
//         icon: 'ti-world'
//       },
// 	  {
//         state: 'fire',
//         name: 'Fire Watch',
//         type: 'link',
//         icon: 'ti-spray'
//       },
// 	  {
//         state: 'foodreport',
//         name: 'Foodreport',
//         type: 'link',
//         icon: 'ti-apple'
//       },
// 	  {
//         state: 'gatepass',
//         name: 'Gatepass',
//         type: 'link',
//         icon: 'ti-ticket'
//       },
// 	  {
//         state: 'invoicevalidation',
//         name: 'Invoice Validation',
//         type: 'link',
//         icon: 'ti-receipt'
//       },
// 	  {
//         state: 'manpower',
//         name: 'Manpower',
//         type: 'link',
//         icon: 'ti-layout-grid2-thumb'
//       },
// 	  {
//         state: 'mom',
//         name: 'Mom',
//         type: 'link',
//         icon: 'ti-calendar'
//       },
// 	  {
//         state: 'noc',
//         name: 'NOC',
//         type: 'link',
//         icon: 'ti-notepad'
//       },
// 	  {
//         state: 'potracker',
//         name: 'PO Tracker',
//         type: 'link',
//         icon: 'ti-envelope'
//       },
// 	  {
//         state: 'procurement',
//         name: 'Procurement',
//         type: 'link',
//         icon: 'ti-layout-list-large-image'
//       },
// 	  {
//         state: 'project',
//         name: 'Project',
//         type: 'link',
//         icon: 'ti-envelope'
//       },
//       {
//         state: 'purchaserequistion',
//         name: 'Purchase Requistion',
//         type: 'link',
//         icon: 'ti-save-alt'
//       },
// 	  {
//         state: 'rack',
//         name: 'Rack',
//         type: 'link',
//         icon: 'ti-id-badge'
//       },
//       {
//         state: 'scrap',
//         name: 'Scrap',
//         type: 'link',
//         icon: 'ti-envelope'
//       },	  
// 	  {
//         state: 'site',
//         name: 'Site',
//         type: 'link',
//         icon: 'ti-location-pin'
//       },
// 	  {
//         state: 'stp',
//         name: 'Stp',
//         type: 'link',
//         icon: 'ti-layout-width-default'
//       },
// 	  {
//         state: 'user',
//         name: 'Users',
//         type: 'link',
//         icon: 'ti-face-smile'
//       },
// 	  {
//         state: 'vendor',
//         name: 'Vendor',
//         type: 'link',
//         icon: 'ti-envelope'
//       },
// 	  {
//         state: 'vehicle',
//         name: 'Vehicle',
//         type: 'link',
//         icon: 'ti-truck'
//       },
//       {
//         state: 'route',
//         name: 'Route',
//         type: 'link',
//         icon: 'ti-truck'
//       },
// 	  {
//         state: 'vehicletrack',
//         name: 'Vehicle Track & Occupancy',
//         type: 'link',
//         icon: 'ti-truck'
//       },
//       {
//         state: 'vehicleroutetag',
//         name: 'Vehicle Route Tag',
//         type: 'link',
//         icon: 'ti-envelope'
//       },
// 	  {
//         state: 'water',
//         name: 'Water',
//         type: 'link',
//         icon: 'ti-layout-width-default'
//       },
// 	    {
//         state: 'workpermit',
//         name: 'Workpermit',
//         type: 'link',
//         icon: 'ti-receipt'
//       },
//       {
//         state: 'workpermit-new',
//         name: 'Workpermit New',
//         type: 'link',
//         icon: 'ti-receipt'
//       },
// 	  {
//         state: 'worksheet',
//         name: 'Worksheet',
//         type: 'link',
//         icon: 'ti-layout-grid2-thumb'
//       },
//     ],
//   },
//   {
//     label: 'Reports',
//     main: 
// 	[
// 	  {
//         state: 'environmentreport',
//         name: 'Environment',
//         type: 'link',
//         icon: 'ti-world'
//       },
//       {
//         state: 'budgetreport',
//         name: 'Accural Report',
//         type: 'link',
//         icon: 'ti-server'
//       },
//       {
//         state: 'tripreport',
//         name: 'Trip Report',
//         type: 'link',
//         icon: 'ti-receipt'
//       },
//       {
//         state: 'consumptionreport',
//         name: 'Consumption Report',
//         type: 'link',
//         icon: 'ti-envelope'
//       },
// 	  {
//         state: 'routetripreport',
//         name: 'Route Wise Trip Report',
//         type: 'link',
//         icon: 'ti-truck'
//       },
      
//     ]
//   },

// ];

const MENUITEMS = [
  {
    label: 'Master',
    main: [
      {
        state: 'miscellaneous',
        name: 'Miscellaneous',
        type: 'link',
        icon: 'ti-home'
      },
	   {
        state: 'technical',
        name: 'Technical ',
        type: 'link',
        icon: 'ti-id-badge'
       },
	  {
        state: 'commercial',
        name: 'Commercial',
        type: 'link',
        icon: 'ti-email'
      },
	  {
        state: 'dailycheck',
        name: 'Daily Check	',
        type: 'link',
        icon: 'ti-notepad'
      },
	  {
        state: 'transport',
        name: 'Transport',
        type: 'link',
        icon: 'ti-notepad'
      },
   	    {
        state: 'complaince',
        name: 'Compliance',
        type: 'link',
        icon: 'ti-bar-chart'
      },

    ],
  },
  {
    label: 'Reports',
    main: 
	[
	  // {
    //     state: 'environmentreport',
    //     name: 'Environment',
    //     type: 'link',
    //     icon: 'ti-world'
    //   },
      {
        state: 'ecr',
        name: 'ECR',
        type: 'link',
        icon: 'ti-world'
      },
      {
        state: 'budgetreport',
        name: 'Accural Report',
        type: 'link',
        icon: 'ti-server'
      },
      {
        state: 'tripreport',
        name: 'Trip Report',
        type: 'link',
        icon: 'ti-receipt'
      },
      {
        state: 'consumptionreport',
        name: 'Consumption Report',
        type: 'link',
        icon: 'ti-envelope'
      },
	  {
        state: 'routetripreport',
        name: 'Route Wise Trip Report',
        type: 'link',
        icon: 'ti-truck'
      },
      
    ]
  },

];


@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
