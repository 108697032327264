
export const environment = {
  production: false,
  baseURL: 'https://api.ebuddy.sdga.in/api/index.php/',
  mediaURL: 'https://api.ebuddy.sdga.in/api/uploads/',
  onesignal: {
    appId: 'YOUR_APP_ID',
    googleProjectNumber: 'GOOGLE_PROJECT_NUMBER',
    restKey: 'REST_KEY'
  },
  general: {
    symbol: '',
    code: 'INR'
  },
  firebaseConfig : {
    apiKey: "AIzaSyA_tk4wB3AeFd_GQBMu4Hz4KCyaiq9vDoo",
    authDomain: "fmbuddy-7f361.firebaseapp.com",
    projectId: "fmbuddy-7f361",
    storageBucket: "fmbuddy-7f361.appspot.com",
    messagingSenderId: "405692734394",
    appId: "1:405692734394:web:42cac4ef82b0fc7d71db83",
    measurementId: "G-GPHWQBTZJ7"
  },
  authToken: 'ebuddy'
};



